<template>
  <div
    class="flex flex-col items-center justify-center absolute top-18 left-0 right-0 bottom-1/4"
  >
    <icon :name="'exclamation-triangle'" :height="'100'" :width="'100'" />
    <span class="mt-4 text-lg font-medium"
      >Sorry, this page does not exist.</span
    >

    <ul class="mt-2 flex flex-row items-center space-x-8">
      <li>
        <router-link class="text-blue-600" :to="{ name: 'Courses' }">
          Home
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Icon from "@/components/Icon.vue";

export default defineComponent({
  components: {
    Icon,
  },
  setup() {
    //
  },
});
</script>
